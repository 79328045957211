import { Box, Flex, FlexProps, Heading, Spacer, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

export interface PageTitleProps extends FlexProps {
  subtitle?: string;
  title: string;
  titleSize?: string;
  submenu?: ReactNode;
  disableSpacer?: boolean;
  leftChild?: ReactNode;
  children?: ReactNode;
  hidden?: boolean;
}

const PageTitle: FC<PageTitleProps> = (props: PageTitleProps) => {
  const {
    subtitle,
    title,
    titleSize = 'md',
    submenu,
    children,
    leftChild,
    disableSpacer,
    hidden = false,
    ...rest
  } = props;

  return (
    <Flex
      borderBottom='1px'
      borderColor='neutral.300'
      w='100%'
      alignItems='center'
      background='chakra-body-bg'
      {...rest}
      hidden={hidden}
    >
      {leftChild}
      <Box>
        {subtitle && (
          <Text fontSize='xs' pl={2}>
            {subtitle}
          </Text>
        )}
        <Heading mx={2} mb={2} mt={subtitle ? 0 : 2} size={titleSize}>
          {title}
        </Heading>
        {submenu}
      </Box>
      {!disableSpacer && <Spacer />}

      {children}
    </Flex>
  );
};

export default PageTitle;
